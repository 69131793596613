<template>
  <div class="shifts-setting">
    <PageTitle
      title="排班班別設定"
      cyBtn1="new-shift-btn"
      btn="新增"
      @btnClick="openDialog('create')"
    />

    <FiltersContainer>
      <el-input
        v-model="nameSearch"
        clearable
        placeholder="輸入排班班別名稱"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </el-input>
    </FiltersContainer>

    <section>
      <el-table
        v-loading="loading"
        :data="shiftsClassList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <el-table-column prop="name" label="名稱" align="center" />
        <el-table-column prop="code" label="代號" align="center" />
        <el-table-column prop="color" label="顏色" align="center">
          <template slot-scope="scope">
            <div
              :style="
                `
            background: ${scope.row.color};
            width: 50%;
            height: 20px;
            margin: auto;
            `
              "
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="操作"
          fixed="right"
          width="110"
          align="center"
        >
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'),
                     syncFormData(scope.row),
                     (selectRow = scope.row),
                     findShiftsClass()"
              @delete=";(deleteDialog = true), (selectRow = scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="shiftsClassCount"
        @pageChange="refresh"
      />
    </section>

    <!-- Dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <el-form
        ref="form"
        :model="formData"
        label-position="top"
        :rules="formRules"
      >
        <el-form-item label="名稱" prop="name">
          <el-input v-model="formData.name" data-cy="name-input" placeholder="早班" />
        </el-form-item>
        <el-form-item label="顏色" prop="color">
          <el-select
            v-model="formData.color"
            data-cy="color-select"
            placeholder="請選擇班別顏色"
            no-data-text="無數據"
            class="color"
          >
            <el-option
              v-for="item in colorList"
              :key="item.name"
              :data-cy="item.name"
              :label="item.name"
              :value="item.name"
            >
              <div
                :style="
                  `margin:5px 0; background: ${item.code}; color: white; width:100%; height: 28px; border-radius:4px;`
                "
              />
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代號" prop="code">
          <el-input v-model="formData.code" data-cy="code-input" placeholder="請輸入班別代號" />
        </el-form-item>
        <div class="mb-2">
          <el-button data-cy="add-period-btn" plain @click="addTimes">新增時段</el-button>
        </div>

        <el-form-item prop="times" label-width="0">
          <div
            v-for="(item, index) in formData.times"
            :key="index + item.start"
            class="flex align-items-stretch mb-2"
          >
            <!-- FIXME 防呆 根據參數決定間隔 -->
            <el-time-select
              v-model="item.start"
              data-cy="start-time-select"
              placeholder="起始時間"
              :picker-options="{
                start: '00:00',
                step: '00:10',
                end: '24:00',
              }"
            />
            <el-time-select
              v-model="item.end"
              data-cy="end-time-select"
              class="w-full ml-2"
              placeholder="結束時間"
              :picker-options="{
                start: item.start,
                step: '00:10',
                end: '24:00',
                minTime: item.start,
              }"
              @change="formData.lastEnd = item.end"
            />
            <el-button
              icon="el-icon-close"
              class="p-2 ml-2"
              @click="formData.times.splice(index, 1)"
            />
          </div>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button
          data-cy="dialog-cancel-btn"
          plain
          @click=";(showDialog = false), resetForm()"
        >
          取 消
        </el-button>
        <el-button data-cy="dialog-confirm-btn" type="primary" @click="dialogConfirm">
          {{
            dialogType === 'create' ? '新增' : '儲存'
          }}
        </el-button>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete=";(deleteDialog = false), deleteShiftsClass()"
    />
  </div>
</template>

<script>
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import {
  CreateShiftsClass,
  GetShiftsClass,
  GetShiftsClassCount,
  DeleteShiftsClass,
  UpdateShiftsClass,
  FindShiftsClass,
} from '@/api/shiftsClass'
import { noEmptyRules, noEmptyTimeInterval } from '@/validation'
// Utils
import { dialogTitle } from '@/utils/dialog'
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { timesConvert } from '@/utils/helper'
import { mapGetters } from 'vuex'
import { shiftColors } from '@/config/reservation'
import { find } from 'lodash'

export default {
  name: 'ShiftsSetting',
  components: { DeleteDialog, EmptyBlock },
  computed: {
    ...mapGetters(['shop']),

    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增排班班別',
        update: '編輯排班班別',
      })
    },

    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    user () {
      return this.$store.state.user
    },
  },
  data: () => ({
    nameSearch: '',
    loading: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: 'create',

    selectRow: null,
    selectShiftClass: null,

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    colorList: shiftColors,
    shiftsClassList: [],
    shiftsClassCount: 0,

    formData: {
      name: '',
      code: '',
      color: '',
      times: [],
    },

    formRules: {
      name: noEmptyRules(),
      color: noEmptyRules(),
      code: noEmptyRules(),
      times: noEmptyTimeInterval(),
    },
  }),

  async mounted () {
    await this.refresh()
  },

  methods: {
    syncFormData (row) {
      this.formData.name = row.name
      this.formData.code = row.code
      const color = find(this.colorList, { code: row.color })
      this.formData.color = color ? color.name : ''
    },
    async refresh () {
      this.loading = true
      await this.getShiftsClass()
      await this.getShiftsClassCount()
      this.loading = false
    },

    async dialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      if (type === 'create') this.createShiftsClass()
      if (type === 'update') this.updateShiftsClass()
      this.showDialog = false
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        name: '',
        code: '',
        color: '',
        times: [],
      }
    },

    //= > 創建班別
    async createShiftsClass () {
      try {
        await CreateShiftsClass({
          shopId: this.shop,
          name: this.formData.name,
          color: find(this.colorList, { name: this.formData.color }).code,
          code: this.formData.code,
          times: this.formData.times,
        })
        await this.refresh()
      } catch (error) {
        this.$message.error(`新增失敗：${error || '未知原因'}`)
        console.log('錯誤', error)
      }
    },

    //= > 刪除服務人員
    async deleteShiftsClass () {
      try {
        await DeleteShiftsClass({
          shopId: this.selectRow.ShopId,
          id: this.selectRow.id,
        })

        await this.refresh()
        this.$message.success('刪除成功!')
      } catch (error) {
        this.$message.error(`創建失敗：${error || '未知原因'}`)
        console.log('錯誤', error)
      }
    },

    //= > 更新服務人員
    async updateShiftsClass () {
      console.log(this.formData.color)
      try {
        await UpdateShiftsClass({
          shopId: this.selectRow.ShopId,
          id: this.selectRow.id,
          name: this.formData.name,
          code: this.formData.code,
          color: find(this.colorList, { name: this.formData.color }).code,
          times: this.formData.times,
        })

        await this.refresh()
        this.$message.success('更新成功!')
      } catch (error) {
        this.$message.error(`更新失敗：${error || '未知原因'}`)
      }
    },

    //= > 取得班別
    async getShiftsClass () {
      try {
        const startIndex = this.pageStartIndex
        const limit = this.tableOptions.pageLimit
        const res = await GetShiftsClass({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: this.nameSearch === '' ? undefined : this.nameSearch,
        })
        this.shiftsClassList = res
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 取得指定班別
    async findShiftsClass () {
      try {
        const res = await FindShiftsClass({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        this.selectShiftClass = res
        this.formData.times = res.AppointmentScheduleDayTimes
        this.formData.times.map((item) => {
          item.start = timesConvert(item.start)
          item.end = timesConvert(item.end)
        })
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 取得班別總數
    async getShiftsClassCount () {
      try {
        const res = await GetShiftsClassCount({
          shopId: this.shop,
          name: this.nameSearch === '' ? undefined : this.nameSearch,
        })
        this.shiftsClassCount = res.count
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    //= > 表單新增時段
    addTimes () {
      const times = this.formData.times
      let start
      if (times.length === 0) {
        start = ''
      } else {
        start = times[times.length - 1].end || ''
      }
      times.push({ start, end: '' })
    },
  },
}
</script>

<style scoped lang="scss">
// ::v-deep .color .el-input__inner {
//   background: chartreuse;
// }
</style>
